import React, {useEffect, useState} from 'react';
import {doc, getDoc, setDoc, addDoc, collection, 
        onSnapshot, query, deleteDoc, updateDoc, 
        arrayUnion, FieldValue, serverTimestamp, 
        getDocs, orderBy, startAt, endAt, where, FieldPath, limit, or, and} from "firebase/firestore";
       
import {useAuth} from "../context/authcontext";
import {db} from "../components/firebase";
import { ToastContainer, toast } from 'react-toastify';
import { SlackErrorHandle } from '../components/SlackErrorMsj'

// export const GetOrders = (ID) =>{
//       return new Promise(async(resolve, reject) => {
//         const docRef = query(collection(db, 'Ordenes'), where("UUID", "==", ID.UUID));
//         await onSnapshot(docRef, (query) => {
//             let dato = [];
//             query.forEach(doc => {
//                 const d = doc.data()
//                 const i = {'Id': doc.id}         
//                 dato.push({...d, ...i})
//             });
//             resolve(dato)
//         }, reject)
//       })
// }

export const GetOrders = async(ID, callback) =>{
  
  try{
    let access = ''
    if (ID.AccessUUID === '-'){
        access = '*'
    }else{
      access = ID.AccessUUID
    }
    
    const docRef = query(collection(db, 'Ordenes'), or(where("UUID", "==", access), where("UUID", "==", ID.UUID), where("AccessUUID","==", ID.UUID), where("AccessUUID","==", access)))
    await onSnapshot(docRef, (query) => {
      let dato = [];
        query.forEach(doc => {
            const d = doc.data()
            if (d.Estado !== "Cancelado") {
              const i = { 'Id': doc.id };
              dato.push({ ...d, ...i });
            }
        });
        
    callback(dato)
  })
  }catch(error){
    console.log(error)
  }
}

export const GetFacturas = async(ID) =>{

    try{
        return new Promise(async(resolve, reject) => {
            const docRef = query(collection(db, 'Facturaciones'), or(where("UUID", "==", ID.AccessUUID), where("UUID", "==", ID.UUID)))
                await onSnapshot(docRef, (query) => {
                  let dato = [];
                    query.forEach(doc => {
                        const d = doc.data()
                        // if (d.Estado === "") {
                          const i = { 'Id': doc.id };
                          dato.push({ ...d, ...i });
                        // }
                    });
                resolve(dato);
            }, reject)
        });
    }catch(error){
      console.log(error)
    }
}

export const GetSkuList = async() =>{
  try {
  // const docRef = doc(db, 'ListaPrecios', 'Skus')
  const docRef = doc(db, 'ListaPrecio', 'Skus') // Todo okay aca
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.data()
  }catch(error){
    console.log(error)
  }
}

export const GetPerfil = (id) => {
    let unsubscribe = null;
    return new Promise(async(resolve, reject) => {
      const docRef = query(collection(db, 'Usuarios'), where("UUID", "==", id)); 
      unsubscribe = await onSnapshot(docRef, (query) => {
        let dato = {};
        query.forEach(doc => {
          const d = doc.data();
          const i = {'Id': doc.id};
          dato = {...d, ...i};
        });
        resolve(dato);
      }, reject);
    });
    // return () => {
    //   if (unsubscribe) {
    //     unsubscribe();
    //   }
    // };
  };

export const GetCategorias  = async() =>{
    let cat = []
    // const querySnapshot = await getDocs(collection(db, "ListaPrecios"));
    const querySnapshot = await getDocs(collection(db, "ListaPrecio")); //Todo okay aca
    querySnapshot.forEach((doc) => {
      const d = doc.data();
      const i = {'Grupo': doc.id};
      cat.push({...d, ...i});
    });
    
    return cat
}

export const GetProducts  = async() =>{
  let cat = []
  // const querySnapshot = await getDocs(collection(db, "ListaPrecios"));
  const querySnapshot = await getDocs(collection(db, "ListaPrecio")); //Todo okay aca
  querySnapshot.forEach((doc) => {
    const d = doc.data();
    const i = {'Grupo': doc.id};
    cat.push({...d, ...i});
  });
  
  // Filtrar los grupos "sku" y "categorias"
  const filteredData = cat
  .filter(group => !group.hasOwnProperty('Categoria') && !group.hasOwnProperty('sku'))
  
  const productList = [];
  // Recorremos cada grupo de productos
  for (const grupo in filteredData) {
      const productos = filteredData[grupo];
      const {Grupo, ...product} = productos
      // Recorremos cada producto dentro del grupo
      for (const producto in product) {          
        productList.push(...product[producto]);
      }
  }

  const pro = productList.reduce((acc, obj) => ({ ...acc, ...obj }), {})
  const prodSku = [pro].map(obj => {
    const newObj = {}
    Object.entries(obj).forEach(([key, value]) => {
      newObj[key] = { Descripción: value, PrecioTaller: 0, PrecioPublico: 0 };
    });
    return newObj;
  })
  return prodSku
}

export const AddOrders = async(ord, docRef) =>{
     
      try{
        await setDoc(docRef, ord)
      }catch(error){
        SlackErrorHandle(`${new Date()}: service.peadoby.com.ar -> Error en agregar orden -> ${error} -> Orden: ${ord}`)
      } 
}

export const Actualizar = async(estado, Actualizado, motivo, id) =>{
 
  const valor = localStorage.getItem('IdOrder');
  
  const docRef = doc(db, "Ordenes", id ? id : valor)
  await updateDoc(docRef, {Estado: estado})
  await updateDoc(docRef, {Actualizado: arrayUnion(Actualizado)})
  await updateDoc(docRef, {MotivoCierre: arrayUnion(motivo)})
  
}

export const ActualizarManoDeObra = async(manoDeObra, operaciones, kmDesplazados, id) =>{
 
  const valor = localStorage.getItem('IdOrder');
  
  const docRef = doc(db, "Ordenes", id ? id : valor)
  await updateDoc(docRef, {ManoDeObra: manoDeObra})
  await updateDoc(docRef, {Operaciones: operaciones})
  await updateDoc(docRef, {KmDesplazados: kmDesplazados})
  
}


export const ActualizarAcentral = async(estado, Actualizado,canje, motivo, id ) =>{

 
  const valor = localStorage.getItem('IdOrder');
 

  const docRef = doc(db, "Ordenes", id ? id : valor)

  await updateDoc(docRef, {AccessUUID:'w3tths9rxmgopRJXmYwI5Mx01ee2'})
  await updateDoc(docRef, {Estado: estado})
  await updateDoc(docRef, {Actualizado: arrayUnion(Actualizado)})
  await updateDoc(docRef, {MotivoCierre: arrayUnion(motivo)})
  await updateDoc(docRef, {ManoDeObra: canje})
  await updateDoc(docRef, {EnviadoAGoldmund: true})
  
  
}

export const GetDolar = async() =>{
  const docRef = doc(db, 'ListaPrecios', 'Dolar')
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.data()
}

export const Getpvp = async() =>{
  try {
  const docRef = doc(db, 'Lista_Precios_PVP', 'WgphFVy1YD959NBajWwP')
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.data()
  }catch(error){
    console.log(error)
  }
}

export const GetDatafromOrder = async (id) =>{
  
  const docRef = doc(db, 'Ordenes', id)
  const data = await getDoc(docRef)

  return(data.data())
}

export const GetRepuesto = async(categoria, articulo) =>{
  
  // const docRef = query(doc(db, 'ListaPrecios', categoria, 'Repuestos', articulo))
  const docRef = query(doc(db, 'ListaPrecio', categoria, 'Repuestos', articulo))
  const datos = await getDoc(docRef)
  if (datos.exists()) {
    const datosDoc = datos.data();
    // console.log(datosDoc)
    return datosDoc;
  } else {
    console.log("El documento no existe");
    return null;
}
}


// export const GetRepuestos = async (Categoria, Articulo) => {
  
//   try {
//     const datos = []
//     // const docRef = doc(db, 'ListaPrecios', Categoria, 'Repuestos', Articulo)
//     const docRef = doc(db, 'ListaPrecio', Categoria, 'Repuestos', Articulo)
//     const querySnapshot = await getDoc(docRef)
//     datos.push(querySnapshot.data())
//     return datos
//   } catch (error) {
//     return []
//   }
// }

export const addPedidoRepuesto = async(iddoc, repuestos) =>{
 
 
  const docRef = doc(db, 'Ordenes', iddoc)
  try{
    const existingData = await getDoc(docRef);
      if (existingData.exists()) {
        const existingRepuestos = existingData.data().Repuestos;

        // Encontrar el valor más alto de 'id' en los repuestos existentes
        const maxId = existingRepuestos.reduce((max, repuesto) => Math.max(max, repuesto.id), 0);

        // Agregar el campo 'id' a los nuevos repuestos desde el valor más alto encontrado
        const repuestosConId = repuestos.map((repuesto, index) => ({
          ...repuesto,
          id: maxId + index + 1 // Asegurar que los nuevos 'id' sean únicos
        }));

        const updatedRepuestos = [...existingRepuestos, ...repuestosConId];
        
        await updateDoc(docRef, { Repuestos: updatedRepuestos, SparePart: false });
        toast.success('Pedido Solicitado',{
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true
        })
      } else {
        
        const existingRepuestos = existingData.data().Repuestos;

        // Encontrar el valor más alto de 'id' en los repuestos existentes
        const maxId = existingRepuestos.reduce((max, repuesto) => Math.max(max, repuesto.id), 0);
        
        const repuestosConId = repuestos.map(repuesto => ({
          ...repuesto,
          id: existingRepuestos.length
        }));

        await updateDoc(docRef, { Repuestos: repuestosConId, SparePart: false });
        toast.success('Pedido Solicitado',{
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true
        })
      }
  }catch(error){
    SlackErrorHandle(`${new Date()}: service.peadoby.com.ar -> Error en agregar estado del repuesto -> ${error} -> datos: ${repuestos}`)
    toast.error('A ocurrido un error, vuelva a cargar el pedido',{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true
    })     
  }
}

export const GetCostoManoDeObra = async () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth(); // Nota: getMonth devuelve 0 para enero, 1 para febrero, etc.
  
  // Obtén el primer día del mes actual
  // const firstDayOfMonth = new Date(`${currentYear}-${currentMonth-1}-01`);
  const firstDayOfMonth = new Date(currentYear,currentMonth,1);
  
  // Obtén el primer día del siguiente mes para limitar la consulta
  // Calcula el primer día del siguiente mes con lógica para el cambio de año
  let nextYear = currentYear;
  let nextMonth = currentMonth;

  if (currentMonth === 11) { // Si es diciembre
    nextYear += 1; // Incrementa el año
    nextMonth = 0; // El siguiente mes será enero
  }

  const firstDayOfNextMonth = new Date(nextYear, nextMonth, 1);
  // const firstDayOfNextMonth = new Date(`${currentYear +1}-${currentMonth +1 }-01`);

  // Realiza una consulta para obtener documentos dentro del rango del mes actual
  const q = query(
      // collection(db, 'CostoManoDeObra'),
      collection(db, 'CostoManoDeObra'),
      // where('Fecha', '>=', firstDayOfMonth),
      where('Fecha', '<', firstDayOfNextMonth)
  );

  try {
      const querySnapshot = await getDocs(q);

      // Verificar si hay documentos encontrados
      if (querySnapshot.size > 0) {
          // Obtener los datos de todos los documentos
          const data = querySnapshot.docs.map((doc) => doc.data());
          
          return data;
      } else {
          console.log('No se encontraron documentos de costo de mano de obra para el mes actual.');
          return null; // Puedes manejar el caso en el que no hay documentos encontrados
      }
  } catch (error) {
      console.error('Error al obtener los documentos:', error);
      return null;
  }
};

export const AddFactura = async (docRef, factura) => {
  try {
      await setDoc(docRef, factura)
      // Itera sobre las órdenes de la factura
      for (const ord of factura.Ordenes) {
          // Obtén la referencia al documento de la orden
          const q = query(collection(db, 'Ordenes'), where("iddoc", "==", ord.iddoc));
          
          // Obtén los documentos de la consulta
          const querySnapshot = await getDocs(q);

          // Itera sobre los documentos devueltos por la consulta
          querySnapshot.forEach(async (doc) => {
              try {
                  // Actualiza el campo "Estado" a "Facturado"
                  await updateDoc(doc.ref, {
                      Estado: "Facturado"
                  });
                  console.log("Campo Estado actualizado a Facturado para el documento:", doc.id);
              } catch (updateError) {
                  console.error("Error al actualizar el documento:", updateError);
              }
          });
      }
  } catch (error) {
      SlackErrorHandle(`${new Date()}: service.peadoby.com.ar -> Error en agregar factura -> ${error} -> Factura: ${factura}`);
  }
};
