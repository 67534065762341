import React, { useEffect,useState } from 'react'
import '../css/Manual_ayuda.css'
import {ModalManual} from '../components/ModalManual'

import micuenta from '../images/micuenta20.png'
import cerrarsesion from '../images/cerrar-sesion.png'
import pestañas from '../images/cuatropestañas.png'
import tableros from '../images/pendientess.png'
import barradebusqueda from '../images/barradebusqueda.png'
import estados from '../images/estado.png'
import excel from '../images/excel.png'
import nuevodocumento from '../images/nuevo-documento.png'
import nuevaorden from '../images/NuevoOrden.png'
import reparacionfinalizacion from '../images/reparacionfinalizacion.png'
import flecha from '../images/flecha.png'
import reparadofinalizado from '../images/reparadofinalizado.png'
import ticknt from '../images/ticknt.png'
import garantia from '../images/Garantía.png'
import factura from '../images/factura.png'
import camara from '../images/camara.png'
import ver from '../images/ver.png'
import solicitarRepuestos from '../images/Estados.png'
import listaDePrecios from '../images/listadeprecios.png'
import pedirRepuestos from '../images/pedir.png'
import solicitud from '../images/solicitud.png'
import estadoDePedidos from '../images/estadodepedidos.png'
import reparacionesCliente from '../images/Reparaciones-Cliente.png'
import estadoRepuesto from '../images/Estado-Repuesto.png'
import busquedaEstados from '../images/Busqueda-Estados.png'
import tabla from '../images/tabla.png'
import imprimir from '../images/imprimir.png'
import sinCargar from '../images/sincargar.png'
import cargado from '../images/cargado.png'
import impresora from '../images/impresora.png'
import modificar from '../images/modificar.png'
import buscarfactura from '../images/buscarfactura.png'
import generarResumen from '../images/generarResumen.png'
import crearfactura from '../images/crearfactura.png'
import subirfactura from '../images/subirfactura.png'
import seleccionfacturar from '../images/seleccionfacturar.png'
import ordenfacturada from '../images/ordenfacturada.png'
import facturadas from '../images/facturadas.png'
{/*a*/}

export const Manual = () => {

    const [imagen, setImagen] = useState()

    useEffect(() => {
        // Detecta el identificador de fragmento en la URL y desplázate al div correspondiente
        const fragmentIdentifier = window.location.hash;
        if (fragmentIdentifier) {
            const targetDiv = document.querySelector(fragmentIdentifier);
            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        // contenedor padre, almacena todo el objeto html
        <div className='padre'>
            {/*Modal que muestra las imágenes*/}
            <ModalManual image={imagen} />

            {/*onClick={() => { setImagen(micuenta) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" */}

            {/*TITULO de la seccion GENERAL*/}
            <h2 style={{ textAlign: 'center', backgroundColor: '#39e', color: '#fff' }}>MANUAL DE USUARIO</h2>

            {/* SUBTITULO de la seccion GENERAL */}
            <h4 style={{ marginBottom: "4rem", textAlign: 'center', backgroundColor: '#eef' }}>PostVenta-Service</h4>

            {/* Primer apartado, INTRODUCCION */}
            <div id="Introduccion">
                {/* parrafo, para hacer saltos de linea usamos <br/> */}
                <p style={{ marginBottom: "4rem", textAlign: 'center' }}>
                    <b>Con este sistema desarrollado por Goldmund - Peabody podremos gestionar las órdenes de servicio de nuestros clientes, visualizar precios de repuestos y pedir repuestos.<br />
                        Además, observar en tiempo real el estado de nuestra compra o de la órden del cliente en servicio.</b>
                </p>
            </div>
            {/* INDICE */}
            {/*En el índice hacer una caja centrada con el texto cómo de leer y decorado- ¿Con o sin decoracion de link? */}
            <h2 className='TitleIn' style={{ textAlign: 'center' }}>Índice</h2>
            <h5 style={{ marginBottom: "4rem", textAlign: 'center', backgroundColor: '#eef', marginBottom: '3rem' }}>¿A dónde quiere ir?</h5>

            <div id='indice'>
                <ul>
                    <li><a style={{ color: 'black', textDecoration: 'none' }} href='#nuevaorden' >¿Cómo puedo hacer una Nueva Orden?</a></li>
                    <li><a style={{ color: 'black', textDecoration: 'none' }} href='#estadoOrden'>¿Dónde puedo ver el estado de la órden? ¿Cómo puedo cambiarlo?</a></li>
                    <li><a style={{ color: 'black', textDecoration: 'none' }} href='#pedir'>¿Cómo puedo solicitar un repuesto?</a></li>
                    <li><a style={{ color: 'black', textDecoration: 'none' }} href='#estado'>¿Cómo puedo ver el estado de mi pedido de un repuesto?</a></li>
                    <li><a style={{ color: 'black', textDecoration: 'none' }} href='#imprimir'>¿Cómo puedo imprimir la órden?</a></li>
                    <li><a style={{ color: 'black', textDecoration: 'none' }} href='#excel'>¿Cómo puedo descargar los datos de la tabla en un Excel?</a></li>
                    <li><a style={{ color: 'black', textDecoration: 'none' }} href='#facturacion'>¿Cómo puedo cargar una factura?</a></li>
                </ul>
            </div>

            {/* Segundo apartado, MI CUENTA */}
            <div style={{ marginTop: "5rem", textAlign: 'center' }} id="micuenta">

                {/* titulo */}
                <h4 className='Title'>MI CUENTA</h4>

                {/* parrafo */}
                <p className='parrafo-manual'>Una vez iniciada la sesión vera este apartado, donde podrá asegurarse de que se trata de su cuenta y de que sus datos son los correctos.</p>
                {/* contenedor con imagen descriptiva */}
                <div className='d-flex justify-content-center' style={{ marginTop: '2rem' }}>
                    <img className='big_image80' src={micuenta} alt='micuenta' onClick={() => { setImagen(micuenta) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                </div>

                <p style={{ marginTop: "5rem", textAlign: 'center' }}>Desde aquí podremos ver el botón de cierre de sesión: <img src={cerrarsesion} alt='cerrarsesionboton' height='5%' width='5%' /></p>
                <div style={{ textAlign: 'center' }}>
                    <p className='parrafo-manual'>Además, verá las cuatro pestañas donde ocurrirá todo el trabajo. Cada una de ellas con una funcionalidad distinta que será explicada a lo largo de este manual de usuario</p>
                    <img className='big_imageNo' src={pestañas} alt='pestañas' onClick={() => { setImagen(pestañas) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                </div>
            </div>

            {/* Tercer apartado, ORDENES DE SERVICIO */}
            <div style={{ marginTop: "5rem", textAlign: 'center' }} id="ordenes">
                <div>
                    <h2 className='Title'>ÓRDENES DE SERVICIO</h2>
                    <p className='parrafo-manual'>Aquí es donde podrá hacer una <b>Nueva Orden</b> y ver el estado e información de sus órdenes ya anotadas.</p>
                    <p className='parrafo-manual'>Cuenta con una herramienta visual e interactiva para comprobar rápidamente las tareas dependiendo la cantidad de días, las órdenes ya tomadas en el día, o los presupuestos pendientes. Toda esta informacion aparecerá clickeando en los recuadros </p>

                    <img className='big_imageNo' src={tableros} alt='tableros' onClick={() => { setImagen(tableros) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                </div>
                <p style={{ marginTop: "2rem" }}>Debajo, se podrá observar una tabla de órdenes ya anotadas.</p>
                <p className='parrafo-manual'>Es una gran lista, por lo que puede contar con dos sistemas de filtros:</p>

                {/* BARRA DE BUSQUEDA */}
                <div className='busqueda'>
                    <div>
                        <p className='larger'><b>Barra de búsqueda:</b></p>
                        <img className='border' src={barradebusqueda} alt='barradebusqueda' height='auto' width='100%' style={{ marginBottom: '2rem' }}/>
                    </div>
                    <div>
                        <p className='larger'><b>Filtro por estado:</b></p>
                        <img className='filtro_ordenes' src={estados} alt='estados' style={{ marginBottom: '2rem' }}/>
                    </div>

                </div>
                <p className='parrafo-manual'>Con la barra de búsqueda puede filtrar por <b>N°Orden, Cliente, Cuit/Dni, Categoría, Artículo e incluso Estado.</b></p>
                <p className='parrafo-manual'>Con el filtro por estado nos podremos visualizar únicamente los productos con <b>un</b> estado de los <b>nueve estados posibles.</b></p>
            </div>

            {/*Cuarto apartado, crear una NUEVA ORDEN*/}
            <div id='nuevaorden' style={{ textAlign: 'center', marginTop: '5rem' }}>
                <h2 className='Title'>CREAR UNA NUEVA ORDEN</h2>
                <p className='parrafo-manual'>En la pestaña "ORDEN", en la esquina superior derecha, podrá observar esta imagen:</p>
                
                <img className='small_image' src={nuevodocumento} alt='Nuevo documento'/>
                <p className='parrafo-manual'>Deberá hacerle click a esa imagen para poder hacer una nueva orden. Una vez dentro, le aparecerá la siguiente pantalla:</p>
                
                <img className='big_image80' src={nuevaorden} alt='Nueva orden' onClick={() => { setImagen(nuevaorden) }} data-bs-toggle="modal" data-bs-target="#Modalimagen" />
                <p className='parrafo-manual'>La información necesaria es explícita. Sin embargo, <b>el mail y la dirección</b> de nuestro cliente son de gran relevancia más allá de lo implícito. </p>
                <p style={{ marginTop: '2rem', marginBottom: '2rem' }}>El mail es <b>importante</b> al ser por ese medio que <u>se conecta a nosotros y a su orden.</u></p>
                <p className='parrafo-manual'> Cuando nosotros demos a un servicio por <u>REPARADO</u>, es en el retiro del producto que el cliente nos proveerá un <b><u>código</u></b> para así nosotros poder dar la orden como <u>FINALIZADA</u>.</p>
            </div>

            {/*Meta: contener 3 imágenes en row */}
            <div className='three_images_row1'>
                <div>
                    <img className='border' src={reparacionfinalizacion} alt='Reparacionfinalizada_firmarretiro' height='auto' width='auto%' />
                </div>
                <div className='flecha'>
                    <img src={flecha} alt='flecha' style={{ height: '50px', width: '50px', alignSelf: 'center' }} />
                </div>
                <div>
                    <img className='border' src={reparadofinalizado} alt='reparado_finalizado' height='auto' width='auto%' />
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <p style={{ marginTop: '4rem' }}>Si el cliente cuenta con GARANTÍA, entonces deberá tocar este botón <img src={ticknt} alt='boton' /> y completar este formulario extra:</p>
                <img className='big_image80' src={garantia} alt='formulario_de_garantía' onClick={() => { setImagen(garantia) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>

                <h5 style={{ marginTop: '4rem' }}>¿Terminó de rellenar los datos del producto?</h5>
                <p className='parrafo-manual'>Recuerde adjuntar la factura de compra haciéndole click a esta imagen: </p>
                <img className='small_image' src={factura} alt='Factura' />
                <p className='parrafo-manual'>Recuerde adjuntar una foto del estado del producto haciéndole click a esta imagen:</p>
                <img className='small_image' src={camara} alt='Cámara' style={{ marginBottom: '2rem' }} />
                <p className='parrafo-manual'><u>Para confirmar la órden debe hacer click en el botón <b>"GUARDAR"</b> en el pie de la página.</u><br /><br />
                </p>
            </div>

            <div className='ad'>
                <p className='parrafo-manual'><b>ADVERTENCIA</b><br /></p>
            </div>
            <div className='advertencia'>
                <p className='parrafo-manual'><b>LA INFORMACIÓN DENTRO DE LA ÓRDEN NO SERÁ MODIFICABLE UNA VEZ SEA GUARDADA. <br />
                    Por favor, asegúrese de que los datos sean correctos.</b></p>
            </div>

            {/* ESTADOS DE LA ORDEN */}
            <div style={{ marginTop: '5rem', textAlign: 'center' }}>


                <h4 id='estadoOrden' className='desde'> ESTADOS DE LA ÓRDEN</h4>

                <p className='parrafo-manual'><b>Una vez hecha la orden, la podrá visualizar en la <u>tabla</u> dentro de Órdenes de Servicio.</b><br />
                    <u>(En esta tabla)</u></p>
                <img className='big_image80' src={tabla} alt='Tabla de Ordenes' style={{ marginBottom: '2rem' }} onClick={() => { setImagen(tabla) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>

                <p className='parrafo-manual'>La órden de manera <u>automática</u>tendrá el estado de <u>“Pendiente”</u>. El estado del producto es <u>modificable</u> tocando la flechita a la derecha del estado <br /> <br />
                    <b>La opción de <u>solicitar repuestos</u> solo aparecerá cuando la orden cuente con garantía.</b> <br /> <br />
                    Si necesitamos <u>solicitar repuestos</u> nos llevarán de manera directa a la pestaña de <b>Pedidos</b>.
                </p>
                <div style={{ marginTop: '2rem' }}>
                    <img className='big_image30' src={solicitarRepuestos} alt='Estados: Solicitar repuestos' onClick={() => { setImagen(solicitarRepuestos) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                </div>
                <p style={{ marginTop: '3rem' }}>Este pedido solicitado desde esta opción cuenta con una característica especial explicada más adelante.</p>
            </div>


            {/* IMPRIMIR ORDEN */}
            <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                <h4 id='imprimir' className='desde'>¿DESEA IMPRIMIR?</h4>
                <p className='parrafo-manual'><b>Si quiere imprimir la orden, debe hacer click en <u>Ver</u>, en la columna “Detalle”.</b></p>

                {/*Acá le falta algo. Quizás el poder clickear de verdad y que se despliegue una imagen simulando ser la factura*/}
                <img className='big_image30' src={ver} alt='Detalle_Ver' style={{ marginTop: '2rem', marginBottom: '2rem' }} onClick={() => { setImagen(ver) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p className='parrafo-manual'><b>Lo siquiente que verá, será esta hoja:</b></p>
                <img className='big_image30' src={imprimir} alt='Hoja para imprimir' onClick={() => { setImagen(imprimir) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p className='parrafo-manual'>Debe darle click a esta imagen para imprimir la hoja</p>
                <img className='small_image' src={impresora} alt='impresora' />
            </div>

            {/* MODIFICAR ORDEN */}
            <div style={{ marginTop: '5rem', textAlign: 'center' }}>

            <h4 id='estadoOrden' className='desde'> MODIFICAR ORDEN</h4>

            <p className='parrafo-manual'><b>Si quiere modificar la orden, debe hacer click en el <u>Lapiz</u>, en la columna “Modificar”.</b></p>
            <img className='big_image30' src={ver} alt='Detalle_Ver' style={{ marginTop: '2rem', marginBottom: '2rem' }} onClick={() => { setImagen(ver) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
            <p className='parrafo-manual'><b>Le aparecerá este apartado:</b></p>
            <p className='parrafo-manual'>Dentro de este apartado va a poder modificar los datos tanto del cliente como del producto.</p>
            <img className='big_image80' src={modificar} alt='Hoja para imprimir' onClick={() => { setImagen(modificar) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>

            </div>

            {/* PEDIR REPUESTOS */}
            <div style={{ marginTop: '10rem', textAlign: 'center' }} >
                <h2 id='pedir' className='Title'><b>PEDIR REPUESTOS</b></h2>
                <h3 className='desde'><u>PEDIR REPUESTOS desde la pestaña <b>PEDIDOS</b></u></h3>
                <p className='parrafo-manual'>En este sector podremos ver la lista de precios de los repuestos del producto que busquemos.<br /> Para ello, tendremos que rellenar los espacios de <b>"Categoría", "Tipo" y "Artículo"</b>, aunque con solo rellenar la última categoría ya aparecerá su lista de repuestos.
                </p>
                <img className='big_image80' src={listaDePrecios} alt='Lista de precios' style={{ marginTop: '2rem', marginBottom: '2rem' }} onClick={() => { setImagen(listaDePrecios) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p className='parrafo-manual'>Para pedir un repuesto, simplemente tendremos que tocar la flecha a la derecha del número en la columna “cantidad”. <br /><br />
                    Una vez hecho, los repuestos pedidos aparecerán debajo de la tabla:
                </p>
                <img className='big_image80' src={pedirRepuestos} alt='PedirRepuestos' style={{ marginTop: '2rem', marginBottom: '2rem' }} onClick={() => { setImagen(pedirRepuestos) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p className='parrafo-manual'>Podremos eliminar un repuesto de el pedido tocando el tacho de de basura en la columna de “Eliminar"<br />
                    Para confirmar nuestro pedido, deberemos tocar el botón verde al pie de pedido.
                </p>
            </div>

            <div style={{ textAlign: 'center', marginTop: '5rem', marginBottom: '5rem' }}>
                <h2 className='desde'> PEDIR REPUESTOS desde la pestaña ORDENES</h2>
                <h5>Utilizando la opción "<u>solicitar repuestos</u>"</h5>
                <p className='parrafo-manual'>Este pedido, al ser un pedido para un producto con garantía no representará un costo para el sector. Los datos del pedido serán automáticos. Nos aparecerá la información del cliente sumado a una tabla de repuestos para el producto en servicio.
                </p>
                <img className='big_image80' src={solicitud} alt='solicitud de repuestos' onClick={() => { setImagen(solicitud) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p style={{ marginTop: '2rem', marginBottom: '2rem' }}>La tabla funciona de igual manera que la anterior ya vista. Sin embargo, una gran diferencia es la necesidad de cargar la solicitud.<br /><br />
                    Esto es lo que se ve cuando ningún producto es cargado para confirmar:
                </p>
                <img className='big_image80' src={sinCargar} alt='Sin Cargar' onClick={() => { setImagen(sinCargar) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p style={{ marginTop: '2rem', marginBottom: '2rem' }}>Esto es lo que se ve cuando el producto ya es cargado (en este caso fueron cargados 3 producto en distintas cantidades):
                </p>
                <img className='big_image80' src={cargado} alt='Cargado' onClick={() => { setImagen(cargado) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p className='parrafo-manual'>Una vez cargado, podemos comprobar si es lo que queremos, de no ser así podremos eliminarlo de la lista. Cuando estén todos los productos dentro haremos click en “confirmar”.
                </p>

            </div>

            {/* ESTADO */}
            <div style={{ textAlign: 'center', marginBottom: '4rem' }} >
                <h2 id='estado' className='Title'>ESTADO</h2>
                <img className='big_image80' src={estadoDePedidos} alt='Estado de Pedidos' onClick={() => { setImagen(estadoDePedidos) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p style={{ marginTop: '2rem' }}><b>Note lo siguiente:</b>En la columna de <b>CLIENTE</b> veremos que puede aparecer el nombre del cliente por el que pedimos el repuesto, o, también, el nombre de Reparaciones SRL (nuestro usuario).<br /><br />
                    El cliente aparecerá de una forma o de otra según donde sea hecho el pedido.
                    Si el pedido fue hecho en la pestaña de PEDIDOS el cliente será Reparaciones SRL.<br />
                    En cambio, si el pedido fue hecho en la pestaña de ÓRDENES, utilizando la opción solicitar repuestos (anteriormente desarrollado) en la misma fila de el cliente, entonces nuestro cliente será el mismo de la órden. En este caso “Nahuel Cualiche” es nuestro cliente con garantía cuyo pedido es específico para su servicio.
                </p>

                <img className='big_image30' src={reparacionesCliente} alt='Usuario y Cliente' style={{ marginTop: '2rem', marginBottom: '2rem' }} onClick={() => { setImagen(reparacionesCliente) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                <p className='parrafo-manual'>Las demás opciones de la tabla ya las conocemos. Contamos con filtros de búsqueda: barra de búsqueda y filtro por estado. Además contamos con la opción de convertir la tabla a un archivo Excel descargable.
                    <img src={excel} alt='excel' height='30px' width='30px' /></p>
                <div className='three_images_row2'>
                    <img className='yaVisto' src={estadoRepuesto} alt='Filtro por Estado' />
                    <img className='yaVisto' src={busquedaEstados} alt='Barra de busqueda' />

                </div>
            </div>

            {/* EXCEL */}
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <h3 className='desde'>¿DESEA ENVIAR LA TABLA A UN EXCEL?</h3>
                <p className='parrafo-manual'><b> Si desea enviar la información de la tabla</b> de <b>ORDENES</b> o la de <b>ESTADO</b> a un archivo <b>EXCEL</b><br />
                    Lo único que debe hacer es darle click a esta imagen que se encuentra en la <u>esquina superior derecha</u> de ambas tablas.</p>

                <div style={{ marginBottom:'3rem'}}>
                    <img className='small_image' id='excel' src={excel} alt='Excel' />
                    <p className='parrafo-manual'> Al precionarla, se le instalará una versión de la tabla como un archivo Excel.</p>
                </div>
            </div>

            {/* FACTURACION */}
            <div style={{ textAlign: 'center', marginBottom: '4rem' }} >
                <h2 id='estado' className='Title'>FACTURACION</h2>
                    <div style={{ marginTop: '2%' }}>
                        <p className='parrafo-manual'>Con la <u>barra de busqueda</u> puede filtrar por: <b>Numero de orden, Cliente, Articulo, Categoria y Sub-Categoria</b></p>
                        <img className='big_image80' src={buscarfactura} alt='Estado de Pedidos' onClick={() => { setImagen(buscarfactura) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                    </div>

                    <div style={{ marginTop: '4%', marginBottom:'4%' }}>
                        <p className='parrafo-manual'>En este apartado podra <b>seleccionar la orden a facturar</b>, la cual le mostrara el importe total en la parte <u>superior derecha</u></p>
                        <img className='big_image80' src={seleccionfacturar} alt='Estado de Pedidos' onClick={() => { setImagen(seleccionfacturar) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                    </div>
                    
                    <h3 className='desde'>ESTADO DE LAS ORDENES</h3>
                    <p className='parrafo-manual'>En esta parte va a poder ver las ordenes ya facturadas y el estado en el que se encuentra</p>
                    <img className='big_image80' src={facturadas} alt='Estado de Pedidos' onClick={() => { setImagen(facturadas) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                    
                    <div style={{ marginTop: '4%', marginBottom:'4%' }}>
                        <p className='parrafo-manual'>Presionando sobre la orden que desee ver podrá observar los detalles de la misma.</p>
                        <img className='big_image80' src={ordenfacturada} alt='Estado de Pedidos' onClick={() => { setImagen(ordenfacturada) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                    </div>
                    
                    <h3 className='desde'>GENERAR RESUMEN</h3>
                    <p className='parrafo-manual'>Va a poder generar un resumen el cual le mostrará, las facturas seleccionada, los detalles de la misma y su importe total</p>
                    <img className='big_image80' style={{ marginBottom:'4%'}} src={generarResumen} alt='Estado de Pedidos' onClick={() => { setImagen(generarResumen) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                    
                    <h3 className='desde'>CREAR FACTURAR</h3>
                    <img className='big_image30' src={crearfactura} alt='Estado de Pedidos' onClick={() => { setImagen(crearfactura) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>

                    <div style={{ marginTop:'2%'}}>
                        <p className='parrafo-manual'>En este apartado podrá subir la factura a elección</p>
                        <img className='big_image30' id='facturacion' src={subirfactura} alt='Estado de Pedidos' onClick={() => { setImagen(subirfactura) }} data-bs-toggle="modal" data-bs-target="#Modalimagen"/>
                    </div>
            </div>

        </div >
    )
}